<div class="carousel-container" *ngIf="images && images.length > 0">
  <img
    *ngFor="let image of images; let i = index"
    [src]="image.imageSrc"
    [alt]="image.imageAlt"
    [ngClass]="{ 'image-active': selectedIndex === i }"
    class="fade"
  />

  <div class="carousel-dot-container" *ngIf="indicators">
    <span
      *ngFor="let dot of images; let i = index"
      class="dot"
      [ngClass]="{ active: selectedIndex === i }"
      (click)="selectImage(i)"
    >
    </span>
  </div>

  <button *ngIf="controls" class="btn-carousel btn-prev">
    <mat-icon class="icon-carousel icon-prev" (click)="onPrevClick()">
      chevron_left
    </mat-icon>
  </button>

  <button *ngIf="controls" class="btn-carousel btn-next">
    <mat-icon class="icon-carousel icon-next" (click)="onNextClick()">
      chevron_right
    </mat-icon>
  </button>
</div>
