import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilityService } from '../../services/utility.service';
import { BACKEND_IP } from '../../constants';
import { userType } from '../../enum';
import { CallUsComponent } from '../call-us/call-us.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmRequestComponent } from '../confirm-request/confirm-request.component';
import { SelectProductToCompareComponent } from '../select-product-to-compare/select-product-to-compare.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MobileFiltersComponent } from '../mobile-filters/mobile-filters.component';
import { FILTERS, APPLIEDFILTERS } from '../../constants';
import { ScrollDownService } from '../../services/scroll-down.service';
import { DAIMETER } from '../../constants';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
  selector: 'app-basic-product',
  templateUrl: './basic-product.component.html',
  styleUrls: ['./basic-product.component.scss']
})
export class BasicProductComponent implements OnInit, OnDestroy {


  images = [
    {
      imageSrc:
        'https://images.unsplash.com/photo-1460627390041-532a28402358?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      imageAlt: 'nature1',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      imageAlt: 'nature2',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1640844444545-66e19eb6f549?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80',
      imageAlt: 'person1',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      imageAlt: 'person2',
    },
  ]
  
  selectedIndex = 0
  products: any = [];
  searchedProducts: any = [];
  originalProducts: any = [];
  noProducts = false;
  public selectedCategory: FormControl = new FormControl();
  public searchProduct: FormControl = new FormControl();
  categories: any;
  backendIp: string;
  user: any;
  toppings: FormGroup;
  filtersVisibility = false;
  isLoading = true;
  isPaginatedLoading = false;
  diameter = DAIMETER;
  basicProductPageSize = 20;
  basicProductPage = 0;
  noproductNow = false;
  apiCallMapping = {
    pageId: 0,
    process: false
  }
  // filters: any[] = [
  //   {
  //     'header': 'Product Item',
  //     'content': []
  //   },{
  //     'header': 'Product Type',
  //     'content': []
  //   },{
  //     'header': 'Accuracy',
  //     'content': []
  //   },{
  //     'header': 'Material',
  //     'content': []
  //   },{
  //     'header': 'Min Range',
  //     'content': []
  //   },{
  //     'header': 'Max Range',
  //     'content': []
  //   },{
  //     'header': 'Min Pressure',
  //     'content': []
  //   },{
  //     'header': 'Max Pressure',
  //     'content': []
  //   },{
  //     'header': 'Min Temprature',
  //     'content': []
  //   },{
  //     'header': 'Max Temprature',
  //     'content': []
  //   },{
  //     'header': 'Media Type',
  //     'content': []
  //   },{
  //     'header': 'Resolution',
  //     'content': []
  //   },{
  //     'header': 'Power Supply',
  //     'content': []
  //   },{
  //     'header': 'Output Signals',
  //     'content': ["MA", "RS232", "RS485"]
  //   },{
  //     'header': 'HART',
  //     'content': []
  //   },{
  //     'header': 'RS232',
  //     'content': []
  //   },{
  //     'header': 'RS485',
  //     'content': []
  //   },{
  //     'header': 'Element Material',
  //     'content': []
  //   },{
  //     'header': 'Housing',
  //     'content': []
  //   },{
  //     'header': 'Paint',
  //     'content': []
  //   },{
  //     'header': 'Range Limit',
  //     'content': []
  //   },{
  //     'header': 'IP',
  //     'content': []
  //   },{
  //     'header': 'Housing Material',
  //     'content': []
  //   },{
  //     'header': 'Hazardous Zone',
  //     'content': []
  //   },{
  //     'header': 'Dimension',
  //     'content': []
  //   }
  // ];

  filters = FILTERS;
  appliedFilters = APPLIEDFILTERS;
  // appliedFilters: any = {
  //   'Product Item': [],
  //   'Product Type': [],
  //   'Accuracy': [],
  //   'Material': [],
  //   'Min Range': [],
  //   'Max Range': [],
  //   'Min Pressure': [],
  //   'Max Pressure': [],
  //   'Min Temprature': [],
  //   'Max Temprature': [],
  //   'Media Type': [],
  //   'Resolution': [],
  //   'Power Supply': [],
  //   'Output Signals': [],
  //   'HART': [],
  //   'RS232': [],
  //   'RS485': [],
  //   'Element Material': [],
  //   'Housing': [],
  //   'Paint': [],
  //   'Range Limit': [],
  //   'IP': [],
  //   'Housing Material': [],
  //   'Hazardous Zone': [],
  //   'Dimension': [],
  // }

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private utility: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
    public scrollService: ScrollDownService
  ) {
    this.backendIp = BACKEND_IP;
    this.toppings = fb.group({});
  }

  ngOnInit(): void {
    window.scroll(10,10);
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.apiService.getAllCategories().subscribe((categories) => {
      this.categories = categories.categories;
      // this.categories.push({
      //   category: "All",
      //   _id: "All"
      // })
    });
    this.route.queryParams.subscribe((qp)=>{
      let category: any = null;
      if (qp.category){
        category = qp.category;
        this.selectedCategory.setValue(qp.category);
      }
      this.getProducts(category);
    });
    this.scrollService.scroll.subscribe((scrollEvent)=>{
      if (scrollEvent && !this.noproductNow){
        if ( this.apiCallMapping.pageId == this.basicProductPage && !this.apiCallMapping.process){
          this.isPaginatedLoading = true;
          this.apiCallMapping.pageId = this.basicProductPage;
          this.apiCallMapping.process = true;
          this.apiService.getBasicProducts(this.selectedCategory.value, this.basicProductPage, this.basicProductPageSize).subscribe((productResp) =>{
            this.basicProductPage = this.basicProductPage + 1;
            this.apiCallMapping.pageId = this.basicProductPage;
            this.apiCallMapping.process = false;
            if (productResp.length === 0){
              // this.snackBar.open("No new products available." , 'OK', {
              //   duration: 7500,
              //   horizontalPosition: 'center',
              //   verticalPosition: 'top'
              // });
              this.noproductNow = true;
            } else{
              this.products = [ ...this.products, ...productResp];
              this.originalProducts = [ ...this.products, ...productResp];
            }
            this.isPaginatedLoading = false;
            this.scrollService.scroll$.next(false);
          }, (err)=>{
            console.log(err);
            this.apiCallMapping.process = false;
            this.isPaginatedLoading = false;
            this.scrollService.scroll$.next(false);
            this.snackBar.open("Error occurred while fetching new products." , 'OK', {
              duration: 7500,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            })
          })
        }
        if(this.searchProduct.value){
          this.searchInProduct();
        }
      }
    })
  }

  ngOnDestroy(): void {
    localStorage.removeItem('products');
    this.apiCallMapping = {
      pageId: 0,
      process: false
    }
  }

  getProducts(category: any){
    this.basicProductPage = 0;
    this.noproductNow = false;
    this.apiCallMapping.pageId = this.basicProductPage;
    this.apiCallMapping.process = true;
    this.apiService.getBasicProducts(category, this.basicProductPage, this.basicProductPageSize).subscribe((productResp) =>{
      this.isLoading = false;
      this.basicProductPage = this.basicProductPage + 1;
      this.apiCallMapping.pageId = this.basicProductPage;
      this.apiCallMapping.process = false;
      if (productResp.length === 0){
        this.noProducts = true;
      } else{
        this.products = productResp;
        this.originalProducts = productResp;
        this.noProducts = false;
      }
      // productResp.forEach((product: { category: { category: any; }; item:{item:any;}}) => {
      //   product.category = product.category.category;
      //   product.item = product.item.item;
      // });
      // this.dataToDisplay = productResp;
      // this.dataSource = new MatTableDataSource(this.dataToDisplay);
      // this.dataSource.sort = this?.sort;
      // this.dataSource.paginator = this.paginator;
    }, (err)=>{
      this.isLoading = false;
      this.apiCallMapping.process = false;
      console.log(err);
      // if (err.status === 401){
      //   this.utility.redirectToSessionExpired()
      // } else{
      //   console.log(err);
      //   this.snackbar.open(err.error.error ? err.error.error : "Error occurred while fetching products." , 'OK', {
      //     duration: 7500,
      //     horizontalPosition: 'center',
      //     verticalPosition: 'top'
      //   })
      // }
    })
  }

  openFullSpec(product: any){
    this.router.navigate([`/fullspec`, product._id]);
  }

  callUs(){
    const config = this.utility.getDialogConfiguration({});
    const dialogRef = this.dialog.open(CallUsComponent, config);
  }

  searchInProduct(){
    let searchItems = this.searchProduct.value.split(" ");
    if (searchItems[0]){
      let searchedProducts: any = [];
      searchItems.forEach((element:any) => {
        this.originalProducts.filter((product:any)=>{
          if (!!product.search_string){
            if(product.search_string.toLowerCase().indexOf(element.toLowerCase()) !== -1){
              if(searchedProducts.indexOf(product) === -1){
                searchedProducts.push(product);
              }
            }
          }
        })
      });
      this.products = searchedProducts;
      this.searchedProducts = searchedProducts;
      if (this.products.length == 0){
        this.noProducts = true;
      }
      this.arrangeFilters();
      this.filtersVisibility = true;
    } else{
      this.products = this.originalProducts;
      this.noProducts = false;
      this.filtersVisibility = false;
    }
    
  }

  resetFilters(){
    this.filters.forEach((element:any) => {
      if (element.header === 'Output Signals'){
        element.content = ["MA", "RS232", "RS485"];
      } else{
        element.content = [];
      }
    })
  }

  arrangeFilters(){
    this.resetFilters();
    this.products.forEach((product:any) => {
      this.filters.forEach((element:any) => {
        if (element.header === 'Product Type'){
          let each_filt = product?.item.product_type;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Product Item'){
          let each_filt = product?.item?.item;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Accuracy'){
          let each_filt = product?.basic_details?.accuracy;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Material'){
          let each_filt = product?.basic_details?.material;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Min Range'){
          let each_filt = product?.basic_details?.min_range;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Max Range'){
          let each_filt = product?.basic_details?.max_range;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Min Pressure'){
          let each_filt = product?.basic_details?.min_pressure;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Max Pressure'){
          let each_filt = product?.basic_details?.max_pressure;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Min Temprature'){
          let each_filt = product?.basic_details?.min_temprature;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Max Temprature'){
          let each_filt = product?.basic_details?.max_temprature;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Media Type'){
          let each_filt = product?.basic_details?.media_type;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Resolution'){
          let each_filt = product?.basic_details?.resolution;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Power Supply'){
          let each_filt = product?.power_supply?.ac;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          } else if (product?.power_supply?.dc && element.content.indexOf(product?.power_supply?.dc) == -1){
            element.content.push(product?.power_supply?.dc);
          }
        } else if (element.header === 'HART'){
          let each_filt = product?.communication_software?.hart;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'RS232'){
          let each_filt = product?.communication_software?.rs232;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'RS485'){
          let each_filt = product?.communication_software?.rs485;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Element Material'){
          let each_filt = product?.transmitter?.element_material;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Housing'){
          let each_filt = product?.transmitter?.housing;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Paint'){
          let each_filt = product?.transmitter?.paint;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Range Limit'){
          let each_filt = product?.transmitter?.range_limit;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'IP'){
          let each_filt = product?.enclosure?.ip_ratting;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Housing Material'){
          let each_filt = product?.enclosure?.housing_material;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Hazardous Zone'){
          let each_filt = product?.enclosure?.hazardous_zone_class;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        } else if (element.header === 'Dimension'){
          let each_filt = product?.enclosure?.dimension;
          if (each_filt && element.content.indexOf(each_filt) == -1){
            element.content.push(each_filt);
          }
        }
      });
      
    });
  }

  selectCategory(val: any){
    if (val === 'All'){
      this.router.navigateByUrl(`?category=${null}`);
    } else{
      this.router.navigateByUrl(`?category=${val}`);
    }
  }

  requestOffer(product:any){
    const config = this.utility.getDialogConfiguration({});
    const dialogRef2 = this.dialog.open(ConfirmRequestComponent, config);
    dialogRef2.afterClosed().subscribe(page => {
      if (!!page && page.status){
        if(this.user){
          if (this.user.user_type === userType.SELLER || this.user.user_type === userType.ADMIN){
            this.router.navigateByUrl(`/login?type=buyer`);
          } else{
            this.utility.requestOffer(product, this.user);
          }
        } else{
          this.router.navigateByUrl(`/login?type=buyer`);
        }
      }
    });
  }

  compareProducts(product:any){
    this.utility.addToCompare(product);
  }

  addFilter(event: any, header: string){
    if (event.checked){
      if(this.appliedFilters[header].indexOf(event.source.value) == -1){
        this.appliedFilters[header].push(event.source.value);
        this.applyFilters();
      }
    } else{
      let eleIndex = this.appliedFilters[header].indexOf(event.source.value);
      if(eleIndex !== -1){
        this.appliedFilters[header].splice(eleIndex, 1);
        this.applyFilters();
      }
    }
  }

  applyFilters(){
    let productsToFilter = this.searchedProducts;
    let filteredProducts: any[] = [];
    let anyFilter = false;
    // Object.keys(this.appliedFilters).forEach(element => {
    //   productsToFilter.forEach((product:any) => {
    //     if (element === 'Product Type'){
    //       if(product?.item.product_type && this.appliedFilters[element].indexOf(product?.item.product_type) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Product Item'){
    //       if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //       productsToFilter = filteredProducts;
    //     } else if (element === 'Accuracy'){
    //       if(product?.basic_details?.accuracy && this.appliedFilters[element].indexOf(product?.basic_details?.accuracy) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Material'){
    //       if(product?.basic_details?.material && this.appliedFilters[element].indexOf(product?.basic_details?.material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Range'){
    //       if(product?.basic_details?.min_range && this.appliedFilters[element].indexOf(product?.basic_details?.min_range) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Range'){
    //       if(product?.basic_details?.max_range && this.appliedFilters[element].indexOf(product?.basic_details?.max_range) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Pressure'){
    //       if(product?.basic_details?.min_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.min_pressure) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Pressure'){
    //       if(product?.basic_details?.max_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.max_pressure) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Temprature'){
    //       if(product?.basic_details?.min_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.min_temprature) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Temprature'){
    //       if(product?.basic_details?.max_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.max_temprature) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Media Type'){
    //       if(product?.basic_details?.media_type && this.appliedFilters[element].indexOf(product?.basic_details?.media_type) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Resolution'){
    //       if(product?.basic_details?.resolution && this.appliedFilters[element].indexOf(product?.basic_details?.resolution) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Power Supply'){
    //       if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Output Signals'){
    //       if(this.appliedFilters[element].indexOf("MA") !== -1 && product?.output_signals?.ma ){
    //         if(product?.output_signals?.ma === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       } else if(this.appliedFilters[element].indexOf("RS232") !== -1 && product?.output_signals?.rs232 ){
    //         if(product?.output_signals?.rs232 === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       } else if(this.appliedFilters[element].indexOf("RS485") !== -1 && product?.output_signals?.rs485 ){
    //         if(product?.output_signals?.rs485 === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       }
    //     } else if (element === 'HART'){
    //       if(product?.communication_software?.hart && this.appliedFilters[element].indexOf(product?.communication_software?.hart) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'RS232'){
    //       if(product?.communication_software?.rs232 && this.appliedFilters[element].indexOf(product?.communication_software?.rs232) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'RS485'){
    //       if(product?.communication_software?.rs485 && this.appliedFilters[element].indexOf(product?.communication_software?.rs485) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Element Material'){
    //       if(product?.transmitter?.element_material && this.appliedFilters[element].indexOf(product?.transmitter?.element_material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Housing'){
    //       if(product?.transmitter?.housing && this.appliedFilters[element].indexOf(product?.transmitter?.housing) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Paint'){
    //       if(product?.transmitter?.paint && this.appliedFilters[element].indexOf(product?.transmitter?.paint) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Range Limit'){
    //       if(product?.transmitter?.range_limit && this.appliedFilters[element].indexOf(product?.transmitter?.range_limit) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'IP'){
    //       if(product?.enclosure?.ip_ratting && this.appliedFilters[element].indexOf(product?.enclosure?.ip_ratting) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Housing Material'){
    //       if(product?.enclosure?.housing_material && this.appliedFilters[element].indexOf(product?.enclosure?.housing_material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Hazardous Zone'){
    //       if(product?.enclosure?.hazardous_zone_class && this.appliedFilters[element].indexOf(product?.enclosure?.hazardous_zone_class) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Dimension'){
    //       if(product?.enclosure?.dimension && this.appliedFilters[element].indexOf(product?.enclosure?.dimension) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     }
    //   });
    //   console.log(`completed ${element} filter`);
    //   console.log(anyFilter);

    //   if (anyFilter){
    //     productsToFilter = filteredProducts;
    //   }
    //   console.log(productsToFilter);
    // });

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Product Type';
      if (element === 'Product Type'){
        if(product?.item.product_type && this.appliedFilters[element].indexOf(product?.item.product_type) !== -1){
          if (filteredProducts.indexOf(product) == -1){
            filteredProducts.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = filteredProducts;
      } else{
        productsToFilter = this.searchedProducts;
      }
    });
    let temp: any = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Product Item';
      if (element === 'Product Item'){
        if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Accuracy';
      if (element === 'Accuracy'){
        if(product?.basic_details?.accuracy && this.appliedFilters[element].indexOf(product?.basic_details?.accuracy) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Material';
      if (element === 'Material'){
        if(product?.basic_details?.material && this.appliedFilters[element].indexOf(product?.basic_details?.material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Range';
      if (element === 'Min Range'){
        if(product?.basic_details?.min_range && this.appliedFilters[element].indexOf(product?.basic_details?.min_range) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Range';
      if (element === 'Max Range'){
        if(product?.basic_details?.max_range && this.appliedFilters[element].indexOf(product?.basic_details?.max_range) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Pressure';
      if (element === 'Min Pressure'){
        if(product?.basic_details?.min_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.min_pressure) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Pressure';
      if (element === 'Max Pressure'){
        if(product?.basic_details?.max_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.max_pressure) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Temprature';
      if (element === 'Min Temprature'){
        if(product?.basic_details?.min_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.min_temprature) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Temprature';
      if (element === 'Max Temprature'){
        if(product?.basic_details?.max_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.max_temprature) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Media Type';
      if (element === 'Media Type'){
        if(product?.basic_details?.media_type && this.appliedFilters[element].indexOf(product?.basic_details?.media_type) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Resolution';
      if (element === 'Resolution'){
        if(product?.basic_details?.resolution && this.appliedFilters[element].indexOf(product?.basic_details?.resolution) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Power Supply';
      if (element === 'Power Supply'){
        if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Output Signals';
      if (element === 'Output Signals'){
        if(this.appliedFilters[element].indexOf("MA") !== -1 && product?.output_signals?.ma ){
          if(product?.output_signals?.ma === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        } else if(this.appliedFilters[element].indexOf("RS232") !== -1 && product?.output_signals?.rs232 ){
          if(product?.output_signals?.rs232 === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        } else if(this.appliedFilters[element].indexOf("RS485") !== -1 && product?.output_signals?.rs485 ){
          if(product?.output_signals?.rs485 === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'HART';
      if (element === 'HART'){
        if(product?.communication_software?.hart && this.appliedFilters[element].indexOf(product?.communication_software?.hart) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'RS232';
      if (element === 'RS232'){
        if(product?.communication_software?.rs232 && this.appliedFilters[element].indexOf(product?.communication_software?.rs232) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'RS485';
      if (element === 'RS485'){
        if(product?.communication_software?.rs485 && this.appliedFilters[element].indexOf(product?.communication_software?.rs485) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Element Material';
      if (element === 'Element Material'){
        if(product?.transmitter?.element_material && this.appliedFilters[element].indexOf(product?.transmitter?.element_material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Housing';
      if (element === 'Housing'){
        if(product?.transmitter?.housing && this.appliedFilters[element].indexOf(product?.transmitter?.housing) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Paint';
      if (element === 'Paint'){
        if(product?.transmitter?.paint && this.appliedFilters[element].indexOf(product?.transmitter?.paint) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Range Limit';
      if (element === 'Range Limit'){
        if(product?.transmitter?.range_limit && this.appliedFilters[element].indexOf(product?.transmitter?.range_limit) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'IP';
      if (element === 'IP'){
        if(product?.enclosure?.ip_ratting && this.appliedFilters[element].indexOf(product?.enclosure?.ip_ratting) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Housing Material';
      if (element === 'Housing Material'){
        if(product?.enclosure?.housing_material && this.appliedFilters[element].indexOf(product?.enclosure?.housing_material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Hazardous Zone';
      if (element === 'Hazardous Zone'){
        if(product?.enclosure?.hazardous_zone_class && this.appliedFilters[element].indexOf(product?.enclosure?.hazardous_zone_class) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Dimension';
      if (element === 'Dimension'){
        if(product?.enclosure?.dimension && this.appliedFilters[element].indexOf(product?.enclosure?.dimension) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });
    if (anyFilter){
      this.products = filteredProducts;
    } else{
      this.products = this.searchedProducts;
    }
  }

  openMobileFilters(){
    const config = this.utility.getDialogConfiguration({});
    config.data.products = this.products;
    config.data.searchedProducts = this.searchedProducts;
    const dialogRef = this.dialog.open(MobileFiltersComponent, config);

    dialogRef.afterClosed().subscribe(page => {

      if (page?.status){
        this.products = page.products;
      }
    });
  }
}
