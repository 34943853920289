<div class="full-specification">
    <div class="uper-cont">
        <h2>{{product?.category?.category}}, {{product?.item?.item}}, and {{product?.item?.product_type}}</h2>
        <div>{{product?.additional_details?.description}}</div>
        <div class="logo">
            <div class="images">
                <div class="frow fsa">
                    <img *ngIf="product?.front" src="{{backendIp}}product/{{product_id}}/front" class="cpointer" (click)="changeDisplayImage('front')">
                    <img *ngIf="product?.left" src="{{backendIp}}product/{{product_id}}/left" class="cpointer" (click)="changeDisplayImage('left')">
                </div>
                <div class="frow fsa">
                    <img *ngIf="product?.top" src="{{backendIp}}product/{{product_id}}/top" class="cpointer" (click)="changeDisplayImage('top')">
                    <img *ngIf="product?.bottom" src="{{backendIp}}product/{{product_id}}/bottom" class="cpointer" (click)="changeDisplayImage('bottom')">
                </div>
                <div class="frow fsa">
                    <img *ngIf="product?.back" src="{{backendIp}}product/{{product_id}}/back" class="cpointer" (click)="changeDisplayImage('back')">
                    <img *ngIf="product?.right" src="{{backendIp}}product/{{product_id}}/right" class="cpointer" (click)="changeDisplayImage('right')">
                </div>
            </div>
            <img class="display-img" src="{{displayImage}}" alt="No Image is available">
        </div>
    </div>
    <div class="separator"></div>
    <div class="other-details">
        <div class="pricing">
            <div class="frow fsb">
                <h2>Price available</h2>
                <div>Item# {{product?.model}}</div>
            </div>
            <div class="frow fsb">
                <div>QTY: 1</div>
                <div>
                    <button mat-button class="btn inverse marg-right-5" mat-raised-button color="primary" (click)="requestOffer()">Request Offer</button>
                    <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="addToCompare()">Add to Compare</button>
                </div>
            </div>
        </div>
        <div>
            {{product?.application}}
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div class="specifications">
    <div class="specs">
        <div class="link">{{product?.model}} Model Options</div>
        <h2>Detailed Specifications</h2>
        <div class="abc">
            <div class="spec-details">
                <div class="header">Basic Details</div>
                <div class="value">Category: {{product?.category?.category}}</div>
                <div class="value">Item: {{product?.item?.item}}</div>
                <div class="value">Product Type: {{product?.item.product_type}}</div>
                <div class="value">Accuracy: {{product?.basic_details?.accuracy}}</div>
                <div class="value">Material: {{product?.basic_details?.material}}</div>
                <div class="value">Min Range: {{product?.basic_details?.min_range}} {{product?.basic_details?.range_unit}}</div>
                <div class="value">Max Range: {{product?.basic_details?.max_range}} {{product?.basic_details?.range_unit}}</div>
                <div class="value">Min Pressure: {{product?.basic_details?.min_pressure}} {{product?.basic_details?.pressure_unit}}</div>
                <div class="value">Max Pressure: {{product?.basic_details?.max_pressure}} {{product?.basic_details?.pressure_unit}}</div>
                <div class="value">Min Temprature: {{product?.basic_details?.min_temprature}} {{product?.basic_details?.temprature_unit}}</div>
                <div class="value">Max Temprature: {{product?.basic_details?.max_temprature}} {{product?.basic_details?.temprature_unit}}</div>
                <div class="value">Media Type: {{product?.basic_details?.media_type}}</div>
                <div class="value">Resolution: {{product?.basic_details?.resolution}}</div>
            </div>
            <div class="separator"></div>
            <div class="spec-details">
                <div class="header">Power Supply</div>
                <div class="value" *ngIf="product?.power_supply?.ac">AC: {{product?.power_supply?.ac}} V</div>
                <div class="value" *ngIf="product?.power_supply?.dc">DC: {{product?.power_supply?.dc}} V</div>
                <div class="header">Output Signals</div>
                <div class="value" *ngIf="product?.output_signals?.ma">MA: {{!!product?.output_signals?.ma ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.output_signals?.rs232">RS232: {{!!product?.output_signals?.rs232 ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.output_signals?.rs485">RS485: {{!!product?.output_signals?.rs485 ? 'Available' : 'Not Available'}}</div>
                <div class="header">Communication Software</div>
                <div class="value">HART: {{product?.communication_software?.hart}}</div>
                <div class="value">RS232: {{product?.communication_software?.rs232}}</div>
                <div class="value">RS485: {{product?.communication_software?.rs485}}</div>
                <div class="header">Transmitter</div>
                <div class="value">Element Material: {{product?.transmitter?.element_material}}</div>
                <div class="value">Housing: {{product?.transmitter?.housing}}</div>
                <div class="value">Paint: {{product?.transmitter?.paint}}</div>
                <div class="value">Range Limit: {{product?.transmitter?.range_limit}}</div>
            </div>
            <div class="separator"></div>
            <div class="spec-details">
                <div class="header">Enclosure</div>
                <div class="value">IP: {{product?.enclosure?.ip_ratting}}</div>
                <div class="value">Housing Material: {{product?.enclosure?.housing_material}}</div>
                <div class="value">Hazardous Zone: {{product?.enclosure?.hazardous_zone_class}}</div>
                <div class="value">Dimension: {{product?.enclosure?.dimension}}</div>
                <div class="header">Installation</div>
                <div class="value" *ngIf="product?.installation_details?.insertion_type">Insertion Type: {{!!product?.installation_details?.insertion_type ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.installation_details?.installation_accessories">Installation Accessories: {{!!product?.installation_details?.installation_accessories ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.installation_details?.mountaing_bracket">Mountaing Bracket: {{!!product?.installation_details?.mountaing_bracket ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.installation_details?.panel_mounted">Panel Mounted: {{!!product?.installation_details?.panel_mounted ? 'Available' : 'Not Available'}}</div>
                <div class="value" *ngIf="product?.installation_details?.pipe_mounted">Pipe Mounted: {{!!product?.installation_details?.pipe_mounted ? 'Available' : 'Not Available'}}</div>
                <div class="header">Cataloges</div>
                <div class="value"><a href="{{backendIp}}product/{{product_id}}/sensor" class="btn clss" target="_self" download>View Sensor Catalog</a></div>
                <div class="value"><a href="{{backendIp}}product/{{product_id}}/instrument" class="btn clss" target="_self" download>View Instrument Catalog</a></div>
                <div class="value"><a href="{{backendIp}}product/{{product_id}}/installation" class="btn clss" target="_self" download>View Installation Manual</a></div>
                <div class="header">Certificates</div>
                <div class="value"><a href="{{backendIp}}product/{{product_id}}/certificates" class="btn clss" target="_self" download>View Certificates</a></div>
            </div>
        </div>
    </div>
</div>

