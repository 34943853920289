import { Component, OnInit, Input } from '@angular/core';

interface carouselImage {
  imageSrc: String;
  imageAlt: String;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() images: carouselImage[] = [];
  @Input() indicators = true;
  @Input() controls = true;
  @Input() autoSlide = true
  @Input() slideInterval=3000

  selectedIndex = 0;
  constructor() {}

  ngOnInit(): void {
    if(this.autoSlide) {
      this.autoSldieImages()
    }

  }

  autoSldieImages(): void {
    setInterval(() => {
      this.onNextClick()
    }, this.slideInterval)
  }

  selectImage(index: number) {
    this.selectedIndex = index;
  }

  onPrevClick(): void {
    if (this.selectedIndex == 0) {
      this.selectedIndex = this.images.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

  onNextClick(): void {
    if (this.selectedIndex === this.images.length - 1) {
      this.selectedIndex=0
    }else {
      this.selectedIndex++
    }
  }
}
